<template>
    <main>
      <div
        class="modal fade"
        id="modalOrdenTrabajo"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Registro de Ordenes
              </h5>
              <button
                type="button"
                class="close"
                @click="cerrar();"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="">
                <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                      <div class="text-center card-waves mt-2 card-header-actions">
                        <div class="card-header ">
                          Usuario
                          <usuario-field v-model="usuario" :validator="$v.usuario" :showName="false"></usuario-field>
                        </div>
                        <div class="card-body " >
                          <div v-if="Object.keys(usuario).length">
                            <div class="form-group">
                              <strong >{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</strong>
                              <h6 class="text-body mt-2">FECHA DE NACIMIENTO: {{usuario.fecha_nacimiento}}</h6>
                              <h6 class="text-body">EDAD : <span class="badge badge-secondary">{{usuario.years}}</span> </h6>
                            </div>
                          </div>
                          <div class="align-center " v-else >
                            <img class="mb-0" src="@/assets/img-generales/error.png" width="96">
                            <p ><strong>Sin datos para mostrar...</strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                          <label class="form-label">No. Orden </label>
                          
                          <orden-field v-model="orden_clinica" :validator="$v.orden_clinica"></orden-field>

                          <div class="row mt-2" v-if="alerts.length">
                            <div class="col-12">
                                <div class="alert alert-danger alert-icon" role="alert">
                                  <div class="alert-icon-aside">
                                      <i class="fas fa-exclamation-triangle fa-fw"></i>
                                  </div>
                                  <div class="alert-icon-content py-3">
                                      <ul>
                                          <li v-for="(alert, i) in alerts" :key="`${i}_alert`">{{alert}}</li>
                                      </ul>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                          <label class="form-label">Fecha </label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="ordentrabajo.fecha"
                            :class="{
                              'is-invalid':
                                $v.ordentrabajo.fecha.$error && $v.ordentrabajo.fecha.$dirty,
                            }"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                      <div class="card-header bg-light "><span><i class="fa-light fa-flask"> </i> Areas</span> </div>
                      <div class="card-body">
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <div class="row">
                                <div class="col-lg-4"  v-for="item in areas_examenes" :key="item.id_area">
                                  <div class="card shadow-none">
                                    <div class="card-header">{{item.nombre}}</div>

                                      <ul class="list-group list-group-flush" v-for="examen in item.examenes" :key="examen.id_examen">
                                        <li class="list-group-item">
                                          <div class="row">
                                            <div class="col-lg-3">
                                              <label class="switchBtn mb-0 " v-show="!examen.sub_examen.length">
                                                <input type="checkbox" v-model="examen.checked" :value="examen.id_examen" >
                                                <div class="slide round"></div> 
                                              </label>
                                            </div>
                                            <div class="col-lg-9">
                                              <h6 class="text-black"> {{examen.nombre}}</h6>
                                            </div>
                                          </div>
                                        </li>
                                        <ul class="list-group list-group-flush text-right" v-for="subexamen in examen.sub_examen" :key="subexamen.id_subexamen">
                                          <li class="list-group-item text-righ">
                                            <div class="row text-righ" >
                                              <div class="col-lg-3">

                                                <label class="switchBtn mb-0 " >
                                                  <input type="checkbox"  v-model="subexamen.checked" :value="subexamen.id_subexamen">
                                                  <div class="slide round"></div> 
                                                </label>

                                              </div>
                                              <div class="col-lg-9 inline">
                                                <h6 class="form-check-label text-black-20"> {{subexamen.nombre}}</h6>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </ul>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="save()"
              >
                Guardar<i class="fa fa-save fa-fw"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-sm"
                @click="cerrar()"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  <script>
  
  import UsuarioField from "../../../../components/common/UsuarioField";
  import OrdenField from "../../../../components/common/OrdenField";
  import ordenTrabajoService from '../../../../services/ordenTrabajoService';

  import AreaLaboratorioService from '../../../../services/AreaLaboratorioService';

  import Toast from "../../../../components/common/utilities/toast";
  import { required } from "vuelidate/lib/validators";
  import $ from "jquery";
  import {isEmpty} from "lodash";
  import moment from "moment";
  
  export default {
    components: { UsuarioField,OrdenField},
    data() {
      return {
        areas_examenes:[],
        examenes_aplicar:[],
        usuario : {},
        orden_clinica:{},
        ordentrabajo: {
          id: "",
          fecha: ""
        },
        type: "store",
      
      };
    },
    validations() {
      return {
        ordentrabajo: {
          fecha: {required}
        },
      };
    },
    watch: {
    },
    methods: {
      async cargarAreasExamenes(){
        const response = await AreaLaboratorioService.index();
        this.areas_examenes= response.data.map(x=>{
          return {
            id_area:x.id,
            nombre:x.nombre,
            examenes:x.examenes.map(e=>{
                return {
                  id_examen:e.id,
                  nombre:e.nombre,
                  checked:'',
                  sub_examen: e.sub_examen.map(sub=>{
                    return {
                      id_subexamen:sub.id,
                      nombre:sub.nombre,
                      checked:'',
                    }
                  })
                }
            }),
          }
        });
      },
      async save() {
        try {
          this.$v.ordentrabajo.$touch();
           
          if (this.$v.ordentrabajo.$invalid) {
            return;
          }

          this.LoaderSpinnerShow();

          let examenes = [];

          this.areas_examenes.forEach(area => {
            area.examenes.forEach(e => {
              if(e.sub_examen.length){
                examenes.push(...e.sub_examen.filter(sub => sub.checked).map(x => x.id_subexamen));
              }else{
                e.checked && examenes.push(e.id_examen);
              }
            })
          });


         let datos={
            ...this.ordentrabajo,
            id_usuario:this.usuario.id,
            id_orden:this.orden_clinica.id,
            examenes:examenes
          }

          if (this.type === "store") {
            await ordenTrabajoService.store(datos);
            this.type = "store";
            this.limpiarCampos();
          } else if (this.type === "edit") {
            await ordenTrabajoService.update(datos);
            this.limpiarCampos();
            this.type = "store";
          } 
  
          this.LoaderSpinnerHide();
  
          Toast.fire({
            icon: "success",
            title: "Datos guardados con exito",
          });

          this.$emit("cargar-ordenes");
  
          $("#modalOrdenTrabajo").modal("hide");
          
        } catch (err) {
          console.error(err);
          this.LoaderSpinnerHide();
          Toast.fire({
            icon: "error",
            title: "Ocurrio un error al procesar la solicitud",
          });
        }
      },
      async limpiarCampos() {
        this.ordentrabajo.fecha="";
        this.ordentrabajo.id="";
        this.examenes_aplicar=[];
        this.usuario = {};
        this.orden_clinica={};
      },
      cerrar() {
        this.type="store";
        this.limpiarCampos();
        this.cargarAreasExamenes();
        $("#modalOrdenTrabajo").modal("hide");
      },
      async edit(id) {

        this.$nextTick(async () => {
          $("#modalOrdenTrabajo").modal("show");
        });

        this.type = "edit";

        const response = await ordenTrabajoService.show(id);
        this.ordentrabajo.id = response.data.id;
        this.usuario = response.data.usuario;
        this.orden_clinica = response.data.orden_clinica;
        this.ordentrabajo.fecha = response.data.fecha;

        this.areas_examenes.forEach((area, index) => {
          area.examenes.forEach((examen, i) => {
            let checked = (response.data.detalles.find(x => parseInt(x.id_examen) ===  parseInt(examen.id_examen))) ? true : false;
            this.areas_examenes[index].examenes[i].checked = checked;
            if(examen.sub_examen.length){
              examen.sub_examen.forEach((sub, j) => {
                let checked = (response.data.detalles.find(x => parseInt(x.id_examen) === parseInt(sub.id_subexamen))) ? true : false;
                this.areas_examenes[index].examenes[i].sub_examen[j].checked = checked;
              })
            }
          })
        });  
         
      },
    },
    async created(){
      await this.cargarAreasExamenes();
    },
    computed: {
      edad: function () {
        if (isEmpty(this.usuarios.fecha_nacimiento)) {
          return "";
        }
        return moment()
          .diff(moment(this.usuarios.fecha_nacimiento, "YYYY-MM-DD"), "year")
          .toString();
      },
      contratos_eps :  function(){
        if(isEmpty(this.usuarios.eps)) return [];
        return this.usuarios.eps.contrato;
      },
      alerts(){

        let alerts = [];

        if(!isEmpty(this.usuario) && !isEmpty(this.orden_clinica)){
          if(this.usuario.id !== this.orden_clinica.historia.id_usuario){
            alerts.push('La orden Ingresada no pertenece al Usuario...');
          }
        }
        
        return alerts;
      }
    },
  };
</script>
  

  <style scoped>
.w-10{
    width: 10%;
}

.w-5{
    width: 5%;
}

/*toogle checkbox*/
.switchBtn {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px;
}
.switchBtn input {display:none;}
.slide {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 8px;
    color: #fff;
}
.slide:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 18px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slide {
    background-color: #06a7ed;
    padding-left: 20px;
}
input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
}
input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -20px;
}
.slide.round {
    border-radius: 24px;
}
.slide.round:before {
    border-radius: 50%;
}

</style>
